<script lang="ts">
	import { titleSuffix } from '$lib/settings/general';

	export let title = 'Active';
	export let desc =
		"Active is Perth's largest gymnastics club; with world-leading coaches, accredited curriculum and two locations to choose from - there's a spot for everyone!";

	const pageName = title + titleSuffix;

	const schemaMarkup = `
{
    "@context": "https://schema.org",
    "@graph": [
    {
        "@type": "WebPage",
        "@id": "https://www.activegym.com.au/",
        "url": "https://www.activegym.com.au/",
        "name": "${pageName}",
    "isPartOf": {
    "@id": "https://www.activegym.com.au/#website"
},
    "about": {
    "@id": "https://www.activegym.com.au/#organization"
},
    "description": "${desc}",
    "inLanguage": "en-AU",
    "potentialAction": [
    {
        "@type": "ReadAction",
        "target": [
            "https://www.activegym.com.au/"
        ]
    }
]
}
]
}
`;

	const schemaEmbed = `<script type="application/ld+json">` + schemaMarkup + `<\/script>`;
</script>

<svelte:head>
	<title>{title}{titleSuffix}</title>
	<meta name="description" content={desc} />

	<meta property="og:title" content="{title}{titleSuffix}" />
	<meta property="og:description" content={desc} />

	{@html schemaEmbed}
</svelte:head>
